import React, { useEffect, useCallback, useMemo, useState } from "react";
import { Power3, gsap } from "gsap/all";
import Observer from "react-intersection-observer";
import { set } from "react-ga";
import numberToCurrency from "@utils/numerToCurrency";

export const DetailsList = ({ slice }) => {
  useEffect(() => {
    initGSAP();
  }, [initGSAP]);

  const handleContentVisibility = useCallback(
    (inView, entry) => {
      if (inView) {
        animateContent();
      }
    },
    [animateContent]
  );
  const headers = [
    "Year",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
    "AMC",
    "Bitcoin",
    "S&P 500",
  ];
  const data = [
    {
      year: 2025,
      values: [
        -3.06,
        -5.86,
        "-",
        "-",
        "-",
        "-",
        "-",
        "-",
        "-",
        "-",
        "-",
        "-",
        //
        -8.75, // amc
        -11.03, // bitcoin
        1.24, // s&p 500
      ],
    },
    {
      year: 2024,
      values: [
        0.46,
        17.00,
        4.92,
        -7.96,
        2.34,
        -5.16,
        -2.36,
        -5.56,
        4.14,
        0.34,
        13.54,
        -3.66,
        //
        16.09, // amc
        120.56, // bitcoin
        23.31, // s&p 500
      ],
    },
    {
      year: 2023,
      values: [
        11.76,
        3.69,
        5.09,
        1.53,
        -2.46,
        2.54,
        -0.56,
        -2.16,
        -1.16,
        7.39,
        4.31,
        5.93,
        //
        41.07, // amc
        157.04, // bitcoin
        24.23, // s&p 500
      ],
    },
    {
      year: 2022,
      values: [
        -1.46,
        1.04,
        6.20,
        -6.36,
        -2.36,
        0.14,
        3.34,
        -0.76,
        -0.86,
        2.44,
        0.34,
        -4.26,
        //
        -3.16, // amc
        -64.75, // bitcoin
        -19.44, // s&p 500
      ],
    },
  ];
  const renderData = useMemo(() => {
    return data.map((row, index) => (
      <tr key={index}>
        <td>{row.year}</td>
        {row.values.map((value, idx) => {
          const isEmpty = value === "-";
          const isNegative = Math.sign(value) === -1;
          const result = !isEmpty ? `${numberToCurrency(value)} %` : value;
          return (
            <td key={idx} className={isNegative ? "red" : ""}>
              {result}
            </td>
          );
        })}
      </tr>
    ));
  }, [data]);
  return (
    <Observer
      threshold="0.1"
      onChange={handleContentVisibility}
      className="wrapper container indents"
    >
      <table className="table_static">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>{renderData}</tbody>
      </table>
    </Observer>
  );

  function initGSAP() {
    gsap.set([".table_static"], {
      opacity: 0,
      y: 100,
    });
  }

  function animateContent() {
    gsap.to(".table_static", {
      opacity: 1,
      y: 0,
      ease: Power3.easeOut,
      delay: 0.4,
    });
  }
};

export default DetailsList;
